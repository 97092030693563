<template>
  <div class="liveRoom">
    <div class="videoRoom_headerHei" style="background:black">
      <div class="iframes-box-ul">
        <ul>
          <li @click="onDrawer(index)" v-for="(item,index) in drawerTitle">{{ item }}</li>
          <li>
            <div class="dow-app">
              <span>下载APP</span>
              <img src="@/assets/home/fire.png" alt />
              <div class="dow-box">
                <img :src="website.qrCodeApp" alt />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- <div class="videoRoom_header_left">
        <img src="@/assets/logo-new.png" alt style="margin-right:10px;" @click="topage('/')" />
        
        <span>{{ videoObj.classHourName }}</span>
        <p class="left-title">{{ liveTitle }}</p>
       
      </div>
      <div class="videoRoom_header_right" style="min-width:32vw;display:flex;justify-content: space-between;">
        <div style="display:flex;">
          <div class="account">
            <span>关注微信公众号</span>
            <img src alt />
            <div class="account-box">
              <img :src="website.qrCodeSidebar" alt />
            </div>
          </div>
          
        </div>

      </div> -->
    </div>

    <!-- <iframe style="border:none;width:100%;height:100%;" :src="reportUrl"></iframe> -->
    <div class="iframes-box">
      <iframe :src="reportUrl" allowfullscreen="true"   class="iframes"></iframe>
      
    </div>

    <el-drawer :title="drawerTitle[drawerIdx]" :visible.sync="drawer" direction="rtl" class="drawer-bg">
      <div v-if="drawerIdx == 0">
        <ul class="ul">
          <li v-for="(item,index) in dataList" :key="index">
            <img src="@/assets/course/handouts_icon.png" alt />
            <p>{{ item.coursewareName }}</p>
            <img class="dow" src="@/assets/course/download_icon.png" alt @click="onFileDown(item.filePath)" />
          </li>
          
        </ul>
      </div>
      <div v-else-if="drawerIdx == 1" class="questions">
        <el-input type="textarea" :rows="5" placeholder="请输入您要提问的内容..." v-model="askTextarea" maxlength="300" resize="none" show-word-limit="true"></el-input>
        <fileUpload isList :options="{}" @fileCallback="fileBack"></fileUpload>
        <div class="videoRoom_main_right_content_noteView_btn">
          <!-- <el-button type="info" @click="dayi_cancel()">取消</el-button> -->
          <el-button type="primary" @click="dayi_add()">提交</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { wangzhanpeizhi } from "@/api/login";
import { coursewareList, live_isBuy } from '@/api/livebroadcast';
import fileUpload from "../../components/FileUpload.vue";
import { dayi_add,watchLive } from '@/api/other';
import { TimeSelect } from "element-ui";
import {
  coursepackage_courseware
} from "@/api/other";
export default {
  name: "liveVideo",
  components: {
    fileUpload
  },
  data() {
    return {
      queryObj: {},
      channelId: 0,
      reportUrl: "",
      videoObj: {},
      userInfo: {},
      website: {},
      liveTitle: '',
      dataList: [],
      drawer: false,
      drawerIdx: 0,
      drawerTitle: [],
      askTextarea: ''
    };
  },
  mounted() {
    this.channelId = this.$route.query.channelId;
    this.liveTitle = this.$route.query.title;
    this.queryObj = this.$route.query;
    let drawerTitle = this.queryObj.liveType == 2 ? ['讲义下载', '我的提问'] : ['讲义下载'];
    this.drawerTitle = drawerTitle;
    document.title = this.liveTitle ? this.liveTitle + '-萨恩课堂' : '萨恩教育';
    this.userInfo = JSON.parse(localStorage.getItem("setuserInfo"));
    console.log(this.userInfo);
    console.log(encodeURI('测试'));
    this.reportUrl = "https://live.polyv.cn/watch/" + this.channelId + '?name=' + encodeURI(this.userInfo.nickName);
    // this.videoObj = JSON.parse(this.$route.query.videoObj);
    console.log('this.reportUrl',this.reportUrl);
    this.wangzhanpeizhi();
    this.live_isBuy();
  },
  methods: {
    wangzhanpeizhi() {
      wangzhanpeizhi().then(res => {
        console.log("网站配置");
        console.log(res);
        if (res.data.code == 0) {
          this.website = res.data.data;
        }
      }).catch(error => {
        console.log(error);
      })
    },
    // 父传子
    getTodos(e) {
      console.log(e);
      this.isUserInfo = e;
    },
    live_isBuy() {
      live_isBuy({ channelId: this.channelId }).then(res => {
        if (res.data.code == 0) {
          let isBuy = res.data.data.isBuy;
          if (isBuy == 1) {
            this.coursewareList();
            this.watchLive();
          } else {
            this.$message.error('您还未购买当前套餐');
            this.$router.replace('/');
          }
        } else {
          this.$message.error(res.data.msg);
        }
      })
    },
    coursewareList() {
      let { courseId } = this.queryObj;
     
      var obj = {
        courseId: courseId,
      };
      coursepackage_courseware(obj)
        .then((res) => {
          if (res.data.code == 0) {
            this.dataList = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      // coursewareList({ channelId: this.channelId }).then(res => {
      //   if (res.data.code == 0) {
      //     console.log(res.data.data);
      //     this.dataList = res.data.data;
      //   } else {
      //     this.$message.error(res.data.msg);
      //   }
      // })

    },
    onFileDown(e) {
      window.open(e, "_blank")
    },
    dayi_add() {
      let fileList = this.fileList;
      let str = "";
      if (fileList != undefined) {
        fileList.forEach((item, index) => {
          str += item.url + ",";
        });
      }
      if (str != "") {
        str = str.substring(0, str.lastIndexOf(","));
      }
      let { id,courseId,coursePackageId } = this.queryObj;
      console.log(id);
      var obj = {
        classHourId: id,
        courseId: courseId,
        coursePackageId: coursePackageId,
        questionContent: this.askTextarea,
        questionPics: str,
      };
      dayi_add(obj)
        .then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.askTextarea = "";
            this.fileList = [];
            this.drawerIdx = 0;
            this.drawer = false;
            this.$message.success('提交成功！');
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fileBack(fileList) {
      this.fileList = fileList;
    },
    onDrawer(idx) {
      this.drawer = true;
      this.drawerIdx = idx;
    },
    watchLive(){
      let id = this.queryObj.id;
      watchLive({liveId:id})
    },
  }
};
</script>

<style lang="scss">
.c-watch__menu-panel {
  display: none;
}
.liveRoom {
  width: 100%;
  height: 100vh;
  .iframes-box-ul {
    margin-left: 30px;
    ul {
      color: #fff;
      list-style: none;
      display: flex;
      li {
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }
}
.c-menu-panel__tabs__item.c-menu-panel__tabs__item--current {
  color: red;
}
.account {
  position: relative;
  margin-right: 20px;
  &:hover {
    .account-box {
      display: block;
    }
  }
  .account-box {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 38px;
    background: #fff;
    padding: 10px;
    display: none;
    img {
      width: 100px;
      height: 100px;
    }
  }
}
.dow-app {
  position: relative;
  &:hover {
    .dow-box {
      display: block;
    }
  }
  .dow-box {
    position: absolute;
    width: 100px;
    height: 100px;
    top: 38px;
    background: #fff;
    padding: 10px;
    display: none;
    img {
      width: 100px;
      height: 100px;
    }
  }
}

.iframes-box {
  width: 100%;
    height: 100vh;
    background-color: black;
    padding: 0;
    margin: 0;
  
  .iframes {
    width: 100%;
    height: 100vh;
    background-color: black;
    border: none;
  }
  
}
.drawer-bg {
  text-align: left;
  color: #fff;
  .el-drawer.rtl {
    background: #2d2d2d;
    .el-drawer__header {
      color: #fff;
      text-align: center;
    }
  }
  .ul {
    margin-top: 16px;
    li {
      list-style: none;
      margin: 0 24px;
      margin-bottom: 26px;
      display: flex;
      justify-content: space-between;
      p {
        word-break: break-all;
      }
      img {
        margin-right: 8px;
        width: 20px;
        height: 20px;
      }
      .dow {
        margin-right: 0;
        margin-left: 8px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
  }
  .questions {
    margin: 0 24px;
    .el-textarea {
      margin-top: 16px;
      textarea {
        background-color: #1d1d1d !important;
        border: 0 !important;
        color: #fff;
      }
      .el-input__count {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #7d7d7d;
        background-color: #1d1d1d !important;
      }
    }
    .el-upload-list--picture-card .el-upload-list__item {
      width: 80px;
      height: 80px;
    }
    .upload-uploader {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;
    }
    .el-upload--picture-card {
      background-color: transparent;
      width: 80px !important;
      height: 80px !important;
      line-height: 80px;
      margin: 0 8px 8px 0;
    }
    .el-menu-item {
      padding-left: 20px !important;
      padding-right: 20px !important;
      &.on {
        background-color: #1d1d1d;
      }
    }
  }
}
</style>